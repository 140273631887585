import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import SharedLayout from '../components/SharedLayout'
import Home from './Home'
import AboutUs from './AboutUs'
import Events from './Events'
import Projects from './Projects'
import Donate from './Donate'

// projects
import ActivismProject from './projects/ActivismAndResilience'
import CounselingForFeministResilience from './projects/CounselingForFeministResilience'
import KagaapayGodawari from './projects/Kagaapay/Godawari'
import KagaapayDuwakot from './projects/Kagaapay/Duwakot'
import KagaapayWorkshopKavre from './projects/KagaapayWorkshop/Kavre'
import KagaapayWorkshopBanke from './projects/KagaapayWorkshop/Banke'
import KagaapayWorkshopKathmandu from './projects/KagaapayWorkshop/Kathmandu'
import KagaapayWorkshopRupandehi from './projects/KagaapayWorkshop/RupandehiWorkshop'
import KagaapayAwarenessBanke from './projects/Kagaapay/Banke'
import KagaapayAwarenessKathmandu from './projects/Kagaapay/Kathmandu'
import KagaapayAwarenessRupandehi from './projects/Kagaapay/Rupandehi.'
import KagaapayAwarenessKavre from './projects/Kagaapay/Kavre'

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route to='/' element={<SharedLayout />}>
                    <Route index element={<Home />} />
                    <Route path='about' element={<AboutUs />} />
                    <Route path='events' element={<Events />} />
                    <Route path='projects' element={<Projects />} />
                    <Route path='donate' element={<Donate />} />
                    <Route
                        path='projects/activism-project'
                        element={<ActivismProject />}
                    />
                    <Route
                        path='projects/counseling-for-feminist-resilience'
                        element={<CounselingForFeministResilience />}
                    />
                    <Route
                        path='projects/kagaapay-awareness-godawari'
                        element={<KagaapayGodawari />}
                    />
                    <Route
                        path='projects/kagaapay-awareness-duwakot'
                        element={<KagaapayDuwakot />}
                    />
                    <Route
                        path='projects/kagaapay-awareness-banke'
                        element={<KagaapayAwarenessBanke />}
                    />
                    <Route
                        path='projects/kagaapay-awareness-kathmandu'
                        element={<KagaapayAwarenessKathmandu />}
                    />
                    <Route
                        path='projects/kagaapay-awareness-rupandehi'
                        element={<KagaapayAwarenessRupandehi />}
                    />
                    <Route
                        path='projects/kagaapay-awareness-kavre'
                        element={<KagaapayAwarenessKavre />}
                    />
                    <Route
                        path='projects/kagaapay-workshop-kavre'
                        element={<KagaapayWorkshopKavre />}
                    />
                    <Route
                        path='projects/kagaapay-workshop-banke'
                        element={<KagaapayWorkshopBanke />}
                    />
                    <Route
                        path='projects/kagaapay-workshop-kathmandu'
                        element={<KagaapayWorkshopKathmandu />}
                    />
                    <Route
                        path='projects/kagaapay-workshop-rupandehi'
                        element={<KagaapayWorkshopRupandehi />}
                    />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}
