import React from 'react'

import About from '../components/About'
import MissionVision from '../components/MissionVision.js'
import Team from '../components/Team'
import Media from '../components/Media'

function AboutUs() {
    return (
        <>
            <About />
            <MissionVision />
            <Team />
            <Media />
        </>
    )
}

export default AboutUs
