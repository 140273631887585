import React from 'react'

import './css/stats.css'

function Stats() {
    return (
        <section
            className='stats center-container padded-container'
            data-aos='flip-up'
        >
            <div className='stat-info'>
                <span>22+</span>
                <br />
                Years
            </div>
            <div className='stat-info'>
                <span>10000+</span>
                <br />
                Clients
            </div>
            <div className='stat-info'>
                <span>200+</span>
                <br />
                Programs
            </div>
            <div className='stat-info'>
                <span>10+</span>
                <br />
                Partnerships
            </div>
        </section>
    )
}

export default Stats
