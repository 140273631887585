import React from 'react'

import RaiWoman from '../assets/images/rai-woman.svg'

import './css/about.css'

export default function About() {
    return (
        <section className='about'>
            <h1>About Inwolag</h1>
            <div className='about-desc center-container'>
                <p data-aos='fade-left'>
                    We are a non-profit, non-governmental, non-political
                    organization established in the year 2000 with the aim of
                    promoting legal rights and awareness among the members of
                    the indigenous/ethnic community. We see women as a diverse
                    group in terms of culture, language, way of living, status,
                    position, literacy, access to resources, and access to
                    decision making, access to services etc. We classify women
                    in these terms and orchestrate our work to meet the needs of
                    each category of women. We mainstream gender equality in our
                    work and also work for marginalized communities of our
                    nation.
                </p>
                <img src={RaiWoman} alt='rai woman' data-aos='fade-right' />
            </div>
        </section>
    )
}
