import React from 'react'

import '../../../css/project.css'

// images

import GroupPhoto from '../../../assets/images/projects/kagaapay/workshop/kavre/2.jpg'
export default function KagaapayWorkshopKavre() {
    return (
        <section className='project-area'>
            <div className='center-container padded-container'>
                <h3>Kavre Workshop</h3>
                <div className='project-image-container'>
                    <figure>
                        <img src={GroupPhoto} alt='Bina Nagarkoti' />
                        <figcaption>
                            Group photo with the Officers and Inwolag team
                        </figcaption>
                    </figure>
                </div>
                <div className='project-description'>
                    <p>
                        Indigenous Women Lawyers Awareness Group (INWOLAG)
                        organized one day program on 31st Jestha, 2080, (June
                        14, 2023) Wednesday Dhulikhel Municipality Hall,
                        Dhulikhel. Total 42 local representatives from the
                        Dhulikhel municipality participated in the program. The
                        first session of the program started at 8am where
                        INWOLAG welcomed the chief guest chief administration
                        officer of Dhulikhel municipality Mr. Surendra Thapa
                        Magar, and guests ward chaipersons from Dhulikhel
                        Municipality, The formal introduction of the INWOLAG and
                        the objectives of the program were briefly explained by
                        Vice chairperson of the INWOLAG Adv. Uma Tamang. The
                        Chief guests gave a brief speech on the importance of
                        the workshop and thanked INWOLAG for such activities.
                        The formal first session was concluded with thanks by
                        the Chairperson of INWOLAG, Adv Indira Shreesh.
                    </p>
                    <p>
                        The second session of the program started with the brief
                        introduction of the participants. The participants were
                        the local officers from all the wards, which included
                        chairperson, women representative member and dalit women
                        representatives from each ward of Dhulikhel
                        municipality. The Psychosocial counsel for the project
                        Ms. Sita Lama presented on the Psychosocial counseling
                        about GBV explaining the need, status, importance of the
                        counseling, also she further presented on case study of
                        suicidal cases.
                    </p>
                    <p>
                        The brief of the project along with objectives was
                        explained by the program officer Adv. Dikshya Shakya,
                        she explained briefly about the cases of legal aid
                        provided by the INWOLAG. She presented on the activities
                        of the project. Besides that she explained the
                        procedures of filing the complaint on violence. She
                        further gave explanation on the violence relating to
                        Sexual Offences. She also brief on the rape, incest, and
                        domestic violence laws. She described about the
                        punishment and limitations of the cases relating to the
                        violence.
                    </p>
                    <p>
                        The secretary of INWOLAG Adv Kaushila Rai presented on
                        the topics of rights of women, collective rights of
                        indigenous women, introduce about the violence, its
                        types and access to justice. She further gave examples
                        of the cases that the indigenous women face domestically
                        and outside the house. She presented on the Gender Based
                        Violence issues where she explained about the violence
                        occurring because of differentiation of gender. She
                        further explained various types of gender based violence
                        issues regarding the Partition, Polygamy, Marriage and
                        Divorce and explained briefly on the provisions of laws
                        relating to them. She described the participants about
                        the punishment and limitations of the cases relating to
                        the violence.
                    </p>
                    <p>
                        The floor was later opened for discussion from the
                        participants where many issues and questioning from the
                        participants were discussed relating to rights of women
                        and mental health issues. The recent case of suicide
                        local indigenous boy was briefly shared by the local
                        representative. The local officers also presented the
                        cases of the suicides and domestic violence has
                        increased through the use of the social media. The few
                        participants told how they have been victim of the
                        violence to women. The questionnaires were answered by
                        the chairperson of INWOLAG. She also suggested the local
                        policy makers for the access to justice as recommended
                        from the CEDAW and CEDAW GR 39.
                    </p>
                </div>
            </div>
        </section>
    )
}
