import React from 'react'

import Tewa from '../assets/images/donors/tewa.png'
import Aipp from '../assets/images/donors/aipp.png'
import MamaCash from '../assets/images/donors/Mama-Cash-Logo.gif'
import WomensFund from '../assets/images/donors/womensfund.png'
import GlobalFund from '../assets/images/donors/global_fund.png'

import './css/donors.css'

function Donors() {
    return (
        <section className='donors center-container padded-container'>
            <h2>Our Donors</h2>
            <div>
                <img src={MamaCash} alt='mama-cash-org' data-aos='fade-right' />
                <img src={Tewa} alt='tewa-logo' data-aos='fade-right' />
                <img
                    src={WomensFund}
                    alt='womens-fund-asia'
                    data-aos='fade-left'
                />
                <img src={Aipp} alt='aipp' data-aos='fade-left' />
                <img src={GlobalFund} alt='global-fund' data-aos='fade-left' />
            </div>
        </section>
    )
}

export default Donors
