import React from 'react'

const mapStyle = {
    flexBasis: '100%',
    border: 'none',
}

export default function Directions() {
    return (
        <section className='directions center-container center-flex padded-container'>
            <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.602758055452!2d85.3190438!3d27.6677595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19fc86cf01c1%3A0xefa5a71b59b21c44!2sInwolag!5e0!3m2!1sen!2snp!4v1666171202692!5m2!1sen!2snp'
                width='600'
                height='450'
                allowFullScreen=''
                loading='lazy'
                style={mapStyle}
                title='inwolag map'
                referrerPolicy='no-referrer-when-downgrade'
            ></iframe>
        </section>
    )
}
