import React from 'react'

import '../../../css/project.css'

// images

import GroupPhoto from '../../../assets/images/projects/kagaapay/kavre/1.jpg'
import Poonam from '../../../assets/images/projects/kagaapay/kavre/2.jpg'
export default function KagaapayAwarenessKavre() {
    return (
        <section className='project-area'>
            <div className='center-container padded-container'>
                <h3>Kavre, Panchkhal, Samudayik Bhawan-12, 2023/01/04</h3>
                <div className='project-image-container'>
                    <figure>
                        <img src={GroupPhoto} alt='Bina Nagarkoti' />
                        <figcaption>
                            Group Photo of the Participants in Kavre Legal
                            Awareness
                        </figcaption>
                    </figure>
                    <figure>
                        <img src={Poonam} alt='Bina Nagarkoti' />
                        <figcaption>
                            Welcoming the guests and chief guests by Adv. Uma
                            Tamang
                        </figcaption>
                    </figure>
                </div>
            </div>
        </section>
    )
}
