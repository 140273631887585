import React from 'react'
import ReactDOM from 'react-dom/client'

import './css/reset.css'
import './css/variables.css'
import './css/global-styles.css'
import './css/portal.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

import App from './pages/App'

AOS.init({
    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 1000, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)
