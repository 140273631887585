import React from 'react'

import './css/media.css'

export default function Media() {
    return (
        <section className='media center-container'>
            <h2>Inwolag in the Media</h2>
            <div className='media-container padded-container'>
                <iframe
                    src='https://www.youtube.com/embed/14v3lVDglBU'
                    title='Indigenous community and hydropower'
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                    data-aos='fade-left'
                ></iframe>
                <iframe
                    src='https://www.youtube.com/embed/5LycovAb6Go'
                    title='Kaushila Rai discusses gender rights'
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                    data-aos='fade-right'
                ></iframe>
            </div>
            <div className='media-container padded-container'>
                <iframe
                    src='https://www.youtube.com/embed/6h-iDbUuRAU?si=8HyQXSlX-ZKzBoMw'
                    title='शिवपुरी नार्गजुन राष्ट्रिय निकुञ्ज र मध्यवर्ती क्षेत्रबाट स्थानिय पिडित'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                    allowfullscreen
                    data-aos='fade-left'
                ></iframe>
                <iframe
                    src='https://www.youtube.com/embed/kekdYDBBacU?si=hGbQcPza5F3oPUuL'
                    title='जलविद्युद उत्पादन, प्रसारण र वितरणका आयोजनाहरुबाट आदिवासी जनजाति अधिकारको गम्भीर उल्लंघन।।'
                    frameborder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                    allowfullscreen
                    data-aos='fade-right'
                ></iframe>
            </div>

            <div className='media-container padded-container'>
                <iframe
                    src='https://www.youtube.com/embed/no0g4BdSwGc?si=pKS4nWm5mIvo_4lM'
                    title='लैङ्गिक हिंसा न्युनीकरणमा जिम्मेवार निकायका पदाधिकारीको भूमिका नै महत्वपूर्ण'
                    frameborder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                    allowfullscreen
                    data-aos='fade-right'
                ></iframe>
            </div>
        </section>
    )
}
