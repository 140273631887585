import React from 'react'

import '../../css/project.css'

// images
import KamalPresenting from '../../assets/images/activism-project/kamal-thapa-presenting.jpg'
import GroupPhoto from '../../assets/images/activism-project/group-photo.jpg'

export default function ActivismAndResilience() {
    return (
        <section className='project-area'>
            <div className='center-container padded-container'>
                <h3>
                    Interactive Program on Indigenous Women Leadership Building
                    for Activism, Access to Resources and Resilience Program
                </h3>
                <div className='project-image-container'>
                    <figure>
                        <img
                            src={KamalPresenting}
                            alt='Presentation on sustainable development'
                        />
                        <figcaption>
                            Kamal Thapa, wildlife expert, presenting on the
                            sustainable development on SNNP and Buffer zone
                        </figcaption>
                    </figure>
                    <figure>
                        <img src={GroupPhoto} alt='Attendees of the event' />
                        <figcaption>
                            Group Photo with participants, chairpersons from
                            different buffer zone committees, and INWOLAG board
                            and staff.
                        </figcaption>
                    </figure>
                </div>
                <div className='project-description'>
                    <p>
                        Indigenous Women Lawyers Awareness Group (INWOLAG)
                        organized one day program Interactive Program on
                        Indigenous Women Leadership Building for Activism,
                        Access to Resources and Resilience" Program on 19th
                        Bhadra, 2080, Tuesday, Dhumbarahi, Kathmandu. The
                        interactive program aimed to discuss existing laws,
                        policies and identify problems faced by the indigenous
                        women regarding the national parks areas along the way
                        for improvement to better protect the rights and safety
                        of Indigenous women in the buffer zone. This program is
                        part of Indigenous Women Leadership Building for
                        Activism, Access to Resources and Resilience, funded by
                        Women's Fund Asia (WFA) under Leading from the South
                        (LFS). Local indigenous women representatives from the
                        Kathmandu district participated in the program.
                    </p>
                    <p>
                        Brief of the Sustainable development of National Parks
                        and buffer zone along with laws and policies was
                        explained by Kamal Thapa, Researcher and Wildlife
                        expert, He emphasized community for the prior initiative
                        emphasized on awareness, women empowerment, meaningful
                        participation, equitable distribution of benefits,
                        transparency, accountability and ease in employee
                        behavior, conducting relief programs and measures to
                        prevent human-wildlife conflict. He also explained the
                        current legal complexity should be reviewed and amended
                        in a timely manner, the rights of indigenous and local
                        communities should be ensured, plans for sustainable
                        tourism should be implemented.
                    </p>
                    <p>
                        On the second session of the program the participants
                        were divided into five different groups according to the
                        different buffer zone committees. The floor was later
                        opened for discussion from the participants where many
                        issues and questions from the participants were
                        discussed relating to problems faced by indigenous women
                        in lifestyle before and after the establishment of
                        national parks and buffer zones. The groups also
                        suggested for the necessary changes to be made for the
                        improvement of nature and livelihood. The meaningful
                        participation of the local representatives was
                        remarkable, which showcased the enthusiasm to cooperate
                        to end women violence issues relating to the national
                        parks. The program was concluded by thanking the
                        participants presented for the program.
                    </p>
                </div>
            </div>
        </section>
    )
}
