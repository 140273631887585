import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

import { faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons'

import './css/footer.css'

export default function Footer() {
    return (
        <section className='org-contact-links' id='contacts'>
            <p className='contact-info'>
                <FontAwesomeIcon icon={faEnvelope} />
                <a href='mailto: inwolag@yahoo.com'>inwolag@yahoo.com</a>
                <br />
                <FontAwesomeIcon icon={faEnvelope} />
                <a href='mailto: inwolag@yahoo.com'>inwolag9@gmail.com</a>
                <br />
                <FontAwesomeIcon icon={faPhone} />{' '}
                <a href='tel:015408706'>015408706</a>
            </p>
            <p className='social-links'>
                <a
                    href='https://www.facebook.com/profile.php?id=100007014618661'
                    alt='facebook-link'
                    target='_blank'
                    rel='noreferrer'
                >
                    <FontAwesomeIcon icon={faFacebook} className='social' />
                </a>
                <a
                    href='https://www.youtube.com/channel/UCWBPhhW-faiKGYgZWI1k-Aw'
                    alt='youtube-link'
                    target='_blank'
                    rel='noreferrer'
                >
                    <FontAwesomeIcon icon={faYoutube} className='social' />
                </a>
                <span>Copyright &#169; 2022 INWOLAG. All rights reserved.</span>
            </p>
        </section>
    )
}
