import React from 'react'

import './css/team.css'

import Indira from '../assets/images/team/indira.png'
import Uma from '../assets/images/team/uma.png'
import Kaushila from '../assets/images/team/kaushila.png'
import Sharda from '../assets/images/team/sharda.png'
import MonKumari from '../assets/images/team/mon-kumari.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserTie } from '@fortawesome/free-solid-svg-icons'

export default function Team() {
    return (
        <section className='our-team center-container'>
            <h2>Our Team</h2>
            <ul>
                <li>
                    <div className='member-card'>
                        <img src={Indira} alt='President' />
                    </div>
                    <div className='member-card-desc'>
                        <h3>Indira Kumari Shreesh</h3>
                        <h4>Chairperson</h4>
                        <p>
                            Adv.Indira Kumari Shreesh is a practicing lawyer in
                            CLRC (Continental legal research center), Lalitpur
                            and has been practising for more than 22 years. She
                            is one of the founding members of INWOLAG. She has
                            been a legal fellow of SAWF from 2013. As an
                            activist, she has been working extensively in the
                            field of providing legal aid to women and children,
                            especially of indigenous and marginal communities.
                        </p>
                    </div>
                </li>
                <li>
                    <div className='member-card'>
                        <img src={Uma} alt='Vice President' />
                    </div>
                    <div className='member-card-desc'>
                        <h3>Uma Tamang</h3>
                        <h4>Vice Chairperson</h4>
                        <p>
                            Adv. UmaTamang is the founding board member of
                            INWOLAG. She has completed her Proficiency
                            Certificate Level and bachelor of law study from
                            Nepal law campus and masters in sociology from Tri
                            Chandra campus. She is also active in Maiti Nepal.
                            She has a lot of experience in legal counseling and
                            advocacy for trafficked women. She has been active
                            in the field of law from very long time.
                        </p>
                    </div>
                </li>
                <li>
                    <div className='member-card'>
                        <img src={Kaushila} alt='General Secretary Kaushila' />
                    </div>
                    <div className='member-card-desc'>
                        <h3>Kaushila Rai</h3>
                        <h4>General Secretary</h4>
                        <p>
                            Adv. Kaushila Rai is a founding member of INWOLAG.
                            She is an indigenours rights activist and advocate.
                            She has her own law firm where she provides legal
                            services. She has completed her master's degree in
                            law from Purbanchal University. She has been
                            practicing law regarding domestic violence,
                            indigenous women's rights, gender rights for more
                            than 22 years.
                        </p>
                    </div>
                </li>
                <li>
                    <div className='member-card'>
                        <FontAwesomeIcon icon={faUserTie} className='icon' />
                    </div>
                    <div className='member-card-desc'>
                        <h3>Ritu Thapa Magar</h3>
                        <h4>Treasurer</h4>
                        <p>
                            Adv. Ritu Thapa Magar holds Masters degree in
                            Conflict Management and International Humanitarian
                            Law. She has completed LL.M. from Pune University,
                            India. She was the second lady to obtain license of
                            advocate from Nepal Bar Council. Ms. Thapa has wide
                            range of experiences in legal sector and NGOs
                            management programs. She has worked as a legal
                            management trainer and an advisor in United Mission
                            to Nepal, as a Legal Officer in Norwegian Refugee
                            Council then as a Program officer in National
                            Democratic Institute in Nepal. Currently, she is
                            actively involved in INWOLAG and sharing her
                            expertise in legal field.
                        </p>
                    </div>
                </li>
                <li>
                    <div className='member-card'>
                        <img src={Sharda} alt='Member Sharda' />
                    </div>
                    <div className='member-card-desc'>
                        <h3>Sharda Subba</h3>
                        <h4>Board member</h4>
                        <p>
                            Adv. Sharda Subba is the founding chairperson of
                            INWOLAG. She is a human right lawyer. She has done
                            her Masters in Human Right and Gender Justice from
                            KSL. She has been actively working as an activist
                            for promoting rights of women and indigenous
                            community through advocacy, free legal aid and
                            training. She actively advises the board on their
                            activities and engages in the organizaiton's
                            programs.
                        </p>
                    </div>
                </li>
                <li>
                    <div className='member-card'>
                        <img src={MonKumari} alt='Member MonKumari' />
                    </div>
                    <div className='member-card-desc'>
                        <h3>Mon Kumari Rai</h3>
                        <h4>Board Member</h4>
                        <p>
                            Adv. Mon Kumari Rai has completed Bachelor in Law
                            from Tribhuvan University, Nepal. She is one of the
                            serving board members of INWOLAG. She has been
                            actively working and activist for promoting rights
                            of women and indigenous community through advocacy,
                            free legal aid and trainings. She engages in regular
                            awareness programs centered around indigenous women
                            regarding their rights and gender violence.
                        </p>
                    </div>
                </li>
            </ul>
        </section>
    )
}
