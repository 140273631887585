import React, { useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import './css/navbar.css'

function Navbar() {
    const menuRef = useRef(null)
    const navLinksRef = useRef(null)

    // When user taps on menu bar, toggle nav links in mobile and tablet
    function onMenuClick() {
        navLinksRef.current.classList.toggle('open-menu')
    }

    function handleNavLinkClick() {
        if (navLinksRef.current.classList.contains('open-menu')) {
            navLinksRef.current.classList.remove('open-menu')
        }
    }

    return (
        <nav>
            <ul className='nav-links' ref={navLinksRef}>
                <li>
                    <NavLink onClick={handleNavLinkClick} to='/'>
                        Home
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={handleNavLinkClick} to='about'>
                        About Us
                    </NavLink>
                </li>
                {/* <li>
                    <NavLink onClick={handleNavLinkClick} to='events'>
                        Events
                    </NavLink>
                </li> */}
                <li>
                    <NavLink onClick={handleNavLinkClick} to='projects'>
                        Projects
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={handleNavLinkClick} to='donate'>
                        Donate
                    </NavLink>
                </li>
            </ul>
            <div className='menu' ref={menuRef} onClick={onMenuClick}>
                <FontAwesomeIcon icon={faBars} className='icon' />
            </div>
        </nav>
    )
}

export default Navbar
