import React from 'react'

import '../../../css/project.css'

// images

import GroupPhoto from '../../../assets/images/projects/kagaapay/workshop/banke/2.jpg'
export default function KagaapayWorkshopBanke() {
    return (
        <section className='project-area'>
            <div className='center-container padded-container'>
                <h3>Banke Workshop</h3>
                <div className='project-image-container'>
                    <figure>
                        <img src={GroupPhoto} alt='Bina Nagarkoti' />
                        <figcaption>
                            Group photo with participants and Inwolag team
                        </figcaption>
                    </figure>
                </div>
                <div className='project-description'>
                    <p>
                        On July 21, 2023, workshop on the Role of Responsible
                        local bodies in the implementation of laws and policies
                        reducing violence against Indigenous women was organized
                        in Nepalgunj, at Hotel City Palace to address the
                        pressing issue of violence against Indigenous women in
                        the Nepalgunj. The workshop aimed to discuss the
                        implementation of existing laws and policies and
                        identify potential areas for improvement to better
                        protect the rights and safety of Indigenous women. This
                        workshop is part of Kaggapay- Legal and Psychological
                        Counselling for Feminist Resilience in the Face of
                        Covid-19, Phase II, funded by WFA. The session started
                        with the existing overview of CEDAW, the existing law,
                        and policies, relevant to the violence against women,
                        especially focusing on the challenges that Indigenous
                        women face. Likewise, CEDAW and the recently released
                        GR-39 of the CEDAW committee were discussed among the
                        participants. Psychologist, Ms. Sita Lama discussed the
                        importance of mental health and the need to focus on the
                        mental health of especially women who have been through
                        any form of violence as it will have a profound and
                        lasting impact on their psychological well-being.
                        Domestic violence is a traumatic and highly distressing
                        experience that can result in severe emotional,
                        psychological, and physical consequences for the victim,
                        explained the psychologist. The participants showed
                        interest and raised questions regarding the issues.
                        Participants engaged in the collaborative discussion to
                        highlight the situation of indigenous women facing
                        violence in different forms. The police officer from the
                        women's cell briefed the participants on the procedure
                        to file the case and informed the process was free of
                        charge. The women's cell will soon be operating the
                        Dalit Cell, informed the officer. The local ward
                        representative encouraged the women participants to
                        discuss their issues in this forum. The vice mayor of
                        the Nepalgunj sub-metropolitan showed commitment to
                        facilitating the women in the legal processing and help
                        in the monetary form to an extent. There was an
                        acknowledgment that while there have been significant
                        strides in addressing gender-based violence; there were
                        still gaps in effective enforcement and awareness. The
                        workshop provided the platform for constructive
                        discussion among the local responsible authorities, and
                        various stakeholders, fostering a commitment to address
                        the pressing issues of violence against Indigenous
                        women. By implementing the recommendation generate
                        during the workshop, it is hoped that significant
                        progress will be made in safeguarding the rights and
                        well-being of Indigenous women and creating a safer and
                        more equitable society for all.
                    </p>
                </div>
            </div>
        </section>
    )
}
