import React, { useRef } from 'react'

import Navbar from './Navbar'
import logo from '../assets/images/logo.png'

import './css/header.css'

function Header() {
    const headerRef = useRef(null)
    /* stick header to top of viewport */
    window.onscroll = function () {
        if (window.pageYOffset >= headerRef.current.offsetTop) {
            headerRef.current.classList.add('sticky')
        } else {
            headerRef.current.classList.remove('sticky')
        }
    }

    return (
        <header ref={headerRef}>
            <img src={logo} alt='logo' className='logo' />
            <Navbar />
        </header>
    )
}

export default Header
