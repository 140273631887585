import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark } from '@fortawesome/free-solid-svg-icons'
import '../css/work-area.css'

export default function Events() {
    return (
        <section className='work-area'>
            <div className='center-container padded-container'>
                <h2>Events</h2>
                <ul className='work-list padded-container'>
                    <li>
                        {/* <a href='#'>
                            <FontAwesomeIcon icon={faBookmark} />
                            <span>October 2016</span>
                        </a> */}
                    </li>
                </ul>
            </div>
        </section>
    )
}
