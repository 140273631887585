import React from 'react'

import '../../../css/project.css'

// images
import Bina from '../../../assets/images/projects/kagaapay/godawari/5.jpg'
import Indira from '../../../assets/images/projects/kagaapay/godawari/3.jpg'
export default function KagaapayGodawari() {
    return (
        <section className='project-area'>
            <div className='center-container padded-container'>
                <h3>Lalitpur,Godawari, Chhampi-9, 2022/12/29</h3>
                <div className='project-image-container'>
                    <figure>
                        <img src={Bina} alt='Bina Nagarkoti' />
                        <figcaption>
                            Bina Nagarkoti, Women Member of ward, giving her
                            speech in Lalitpur awareness
                        </figcaption>
                    </figure>
                    <figure>
                        <img src={Indira} alt='Indira Shreesh' />
                        <figcaption>
                            Adv. Indira Shreesh, Chairperson, sharing with
                            participants about the legal rights
                        </figcaption>
                    </figure>
                </div>
                <div className='project-description'>
                    <p>
                        Indigenous Women Lawyers Awareness Group (INWOLAG)
                        organized one day program on 14th Poush, 2079, Thursday
                        at Indrakamal Sahakari Krishi Bhawan,Itahiti, Godawari
                        Municipality ward no 9, Lalitpur. Total 44 local
                        indigenous women from the Nagarkoti community
                        participated in the program.
                    </p>
                    <p>
                        The first session of the program started formally
                        welcomed the Chief guests Chairperson of ward no 9,Aatma
                        Ram Thapa, guests-ward member Jagat Bahadur Singtan,
                        Dalit women member, Jamuna Balkoti, Executive member
                        Bina Nagarkoti and Chairperson of Pasa Misa Nagarkoti
                        Samuha, Kali Maya Nagarkoti. The formal introduction of
                        the INWOLAG and the objectives of the program were
                        briefly explained by Adv. Dikshya Shakya, Legal Officer
                        of the INWOLAG. The formal session was concluded with
                        thanks by the Chairperson of INWOLAG, Adv Indira Shreesh
                        after the brief speech by the respective guests and
                        chief guest.
                    </p>
                    <p>
                        The second session of the program started at 12.30 with
                        the brief introduction of the participants. Most of the
                        participants were from the Nagarkoti community nearby
                        who were doing local business, farming, agriculture and
                        few were housewives with no other income generating
                        activities. The chairperson of INWOLAG Adv Indira
                        Shreesh presented on the topics of rights of women,
                        collective rights of indigenous women, introduce about
                        the violence and its types. She further gave examples of
                        the cases that the indigenous women face domestically
                        and outside the house. Narishma Shrestha a legal
                        associate of INWOLAG gave her presentation on the Gender
                        Based Violence where she explained about the violence
                        occurring because of differentiation of gender. She
                        further explained various types of gender based violence
                        issues. Executive Member of INWOLAG Adv Mon Kumari Rai
                        introduced the participants regarding the Partition,
                        Polygamy, Marriage and Divorce. She explained briefly on
                        the provisions of laws relating to them. She described
                        the participants about the punishment and limitations of
                        the cases relating to the violence. Adv Dikshya Shakya
                        gave the presentation on the violence relating to Sexual
                        Offences. She briefly explained on the rape, incest, and
                        domestic violence relating to the sex. She described the
                        participants about the punishment and limitations of the
                        cases relating to the violence.
                    </p>
                    <p>
                        The floor was later opened for discussion from the
                        participants where many issues and questioning from the
                        participants were discussed relating to divorce,
                        partition and citizenship. The few participants told how
                        they have been victim of the violence to women. Atlast,
                        the chairperson of INWOLAG concluded the program by
                        thanking the participants presented in the program.
                    </p>
                </div>
            </div>
        </section>
    )
}
