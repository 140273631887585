import React from 'react'
import Banner from '../components/Banner'
import Introduction from '../components/Introduction'
import Activities from '../components/Activities'
import Stats from '../components/Stats'
import Donors from '../components/Donors'
import Directions from '../components/Directions'

function Home() {
    return (
        <>
            <Banner />
            <Introduction />
            <Activities />
            <Stats />
            <Donors />
            <Directions />
        </>
    )
}

export default Home
