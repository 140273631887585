import React from 'react'

import '../../../css/project.css'

// images

import Kaushila from '../../../assets/images/projects/kagaapay/workshop/rupandehi/1.jpg'
export default function KagaapayWorkshopRupandehi() {
    return (
        <section className='project-area'>
            <div className='center-container padded-container'>
                <h3>Rupandehi Workshop</h3>
                <div className='project-image-container'>
                    <figure>
                        <img src={Kaushila} alt='Bina Nagarkoti' />
                        <figcaption>
                            Group photo of the participants with Mayor and Vice
                            Mayor of Tillottama Municipality in Rupandehi
                            Workshop
                        </figcaption>
                    </figure>
                </div>
                <div className='project-description'>
                    <p>
                        On July 20, 2023, a workshop on the Role of Responsible
                        local bodies in the implementation of laws and policies
                        reducing violence against Indigenous women was organized
                        in Rupandehi, at Dreamland Gold Resort and Hotel,
                        Manigram, Rupandehi to address the pressing issue of
                        violence against Indigenous women in the Rupandehi. The
                        workshop aimed to discuss the implementation of existing
                        laws and policies and identify potential areas for
                        improvement to better protect the rights and safety of
                        Indigenous women. This workshop is part of Kaggapay-
                        Legal and Psychological Counselling for Feminist
                        Resilience in the Face of Covid-19, Phase II, funded by
                        WFA. The session started with the presentation from
                        Program Officer Adv Dikshya Laxmi Shakya about the brief
                        of the KAAGAPAY-Legal and Psychological Counseling for
                        Feminist Resilience in the Face of Covid-19, Phase II,
                        funded by WFA project. She explained briefly about the
                        objectives, activities of the project, and legal aid
                        provided by the INWOLAG. She explained on the laws and
                        policies related to rape, incest, domestic violence and
                        also violence relating to Sexual Offences. She also
                        presented on situational status of the cases of the
                        working districts. The focal person of the KAAGAPAY for
                        Rupandehi Adv. Kamala Bahik discussed the situational
                        status of the cases from Rupandehi. She also explained
                        the methods for legal counseling for the other cases
                        that require the legal aid. The Treasurer of INWOLAG Adv
                        Ritu Thapa started session with the existing overview of
                        CEDAW, the existing law, and policies, relevant to the
                        violence against women, especially focusing on the
                        challenges that Indigenous women face. Likewise, CEDAW
                        and the recently released GR-39 of the CEDAW committee
                        were discussed among the participants. The importance of
                        mental health and the need to focus on the mental health
                        of especially women who have been through any form of
                        violence as it will have a profound and lasting impact
                        on their psychological well-being. Domestic violence is
                        a traumatic and highly distressing experience that can
                        result in severe emotional, psychological, and physical
                        consequences for the victim, explained the psychologist.
                        The participants showed interest and raised questions
                        regarding the issues. Participants engaged in the
                        collaborative discussion to highlight the situation of
                        indigenous women facing violence in different forms. The
                        local ward representative encouraged the women
                        participants to discuss their issues in this forum. The
                        mayor of the Tilottama Municipality, Ram Krishna Khand
                        showed commitment to facilitating the women in the legal
                        processing and help in the monetary form to an extent.
                        He also encouraged the other local ward representatives
                        for collaboration in activities of the municipalities.
                        He explained the municipality was made child friendly
                        environment and is prioritizing for the gender friendly.
                        The deputy mayor, Jageshwori Chaudhari focused the women
                        to be economically powerful in order to cope with the
                        GBV issues. She also ensured the municipality was
                        launching different projects for increment of economic
                        empowerment of women. There was an acknowledgment that
                        while there have been significant strides in addressing
                        gender-based violence; there were still gaps in
                        effective enforcement and awareness. The workshop
                        provided the platform for constructive discussion among
                        the local responsible authorities, and various
                        stakeholders, fostering a commitment to address the
                        pressing issues of violence against Indigenous women. By
                        implementing the recommendation generate during the
                        workshop, it is hoped that significant progress will be
                        made in safeguarding the rights and well-being of
                        Indigenous women and creating a safer and more equitable
                        society for all.
                    </p>
                </div>
            </div>
        </section>
    )
}
