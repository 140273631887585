import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Donate from '../pages/Donate'
import woman from '../assets/images/woman.svg'

import './css/banner.css'

function Banner() {
    const navigate = useNavigate()

    function navigateToAboutUs() {
        navigate('/about')
    }

    function navigateToDonate() {
        navigate('/donate')
    }

    function scrollToBottom() {
        window.scroll({
            top: document.body.offsetHeight,
            left: 0,
            behavior: 'smooth',
        })
    }

    return (
        <section className='banner'>
            <div className='org-intro'>
                <div className='org-name'>
                    <h1>INWOLAG</h1>
                    <p>Indigenous Women Legal Awareness Group</p>
                </div>
                <div className='banner-btns'>
                    <button
                        className='learn-more-btn'
                        onClick={navigateToAboutUs}
                    >
                        Learn More
                    </button>
                    <button className='contact-btn' onClick={scrollToBottom}>
                        Contact Us
                    </button>
                    <button className='donate-btn' onClick={navigateToDonate}>
                        Donate Now
                    </button>
                </div>
            </div>
            <img src={woman} alt='woman' />
        </section>
    )
}

export default Banner
