import React from 'react'

import '../../css/project.css'

// images
import KamalPresenting from '../../assets/images/activism-project/kamal-thapa-presenting.jpg'
import GroupPhoto from '../../assets/images/activism-project/group-photo.jpg'

export default function CounselingForFeministResilience() {
    return (
        <section className='project-area'>
            <div className='center-container padded-container'>
                <h3>
                    Legal and Psychological Counselling for Feminist Resilience
                    in the face of COVID-19, Phase II
                </h3>

                <div className='project-description'>
                    <p>
                        INWOLAG a right based organization following its vision
                        and mission in this scenario launched a project to
                        assist the GBV victims, with financial support from WFA.
                        Six different districts were chosen for the intervention
                        where focal persons in different districts were assigned
                        to assist the victim with legal aid. Districts included
                        Kathmandu, Lalitpur, Bhaktapur, Kavre, Rupandehi and
                        Banke. All the appointees were female having experience
                        in dealing cases related to Women violence and legal
                        expertise. The purpose of female appointee as a legal
                        advisor was to provide gender friendly environment for
                        the victim, enabling them to express freely and
                        comfortably.
                    </p>
                    <p>
                        As the part of the activities, Workshop program and
                        Legal Awareness in 6 different districts were conducted
                        to meet the desire objective of the project. 6 different
                        awareness programs were conducted in different districts
                        while 4 different workshops were conducted collaborating
                        different districts in overall time period.
                    </p>
                    <p>
                        Legal awareness was conducted among the indigenous women
                        community for providing them the knowledge about the
                        gender based violence issues, laws and policies and the
                        impactful ways to cope with the problems. Total of 256
                        local indigenous women from six districts participated
                        during the legal awareness by the legal counselors.
                        Workshop program was conducted among the local officers,
                        representatives from governmental bodies, attorneys and
                        GBV survivors to sensitize the local officers regarding
                        the GBV issues faced by the GBV survivors. The workshop
                        also helped in building up the coordination and
                        networking among the government, survivors and the
                        organizations. Total 163 participants participated in
                        the workshop program.
                    </p>
                    <p>
                        Legal Counseling and Psychosocial counseling were
                        provided throughout the year by focal persons and
                        counselors in the different districts. The total number
                        of the cases dealt by the INWOLAG is twenty nine, which
                        includes eleven cases of Rape/Sexual Violence/Child
                        sexual abuse, one case of abduction and trafficking from
                        Banke district. Three cases of writs, two cases of
                        domestic violence, total nine cases of divorce,
                        partition, alimony and transaction from different
                        districts while two cases of defamation were received
                        from Kathmandu and one case of accidental homicide from
                        Banke district. The project started from July, 2022 and
                        completed on August 15, 2023.
                    </p>
                </div>
            </div>
        </section>
    )
}
