import React from 'react'

import '../../../css/project.css'

// images

import GroupPhoto from '../../../assets/images/projects/kagaapay/rupandehi/1.jpg'
export default function KagaapayAwarenessRupandehi() {
    return (
        <section className='project-area'>
            <div className='center-container padded-container'>
                <h3>Rupandehi, Tilottama, Semarabajar, 2023/01/04</h3>
                <div className='project-image-container'>
                    <figure>
                        <img src={GroupPhoto} alt='Bina Nagarkoti' />
                        <figcaption>
                            1. Adv Ritu Thapa, Treasurer, welcoming the
                            participants in Rupandehi legal awareness
                        </figcaption>
                    </figure>
                </div>
            </div>
        </section>
    )
}
