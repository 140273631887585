import React from 'react'

import './css/mission-vision.css'
import Mission1 from '../assets/images/mission-vision/mission-1.jpg'
import Mission2 from '../assets/images/mission-vision/mission-2.jpg'
import Mission3 from '../assets/images/mission-vision/mission-3.jpeg'

export default function MissionVision() {
    return (
        <section className='mission-vision center-container padded-container'>
            <div className='mission-vision-desc'>
                <h3>Mission</h3>
                <p>
                    To create an egalitarian and prospective society where there
                    is no domination and discrimination based on race, caste,
                    ethnicity, language, religion, culture, gender, class and
                    region.
                </p>
                <h3>Vision</h3>
                <p>
                    To create a condition where indigenous women are recognized;
                    and language as well as culture of indigenous peoples are
                    respected.
                </p>
                <h3>Objectives</h3>
                <ol>
                    <li>
                        To increase access to justice to indigenous women and
                        children by providing legal aid service, legal
                        counseling, legal advocacy and mediation to indigenous
                        women and children to secure their legal and human
                        rights.
                    </li>
                    <li>
                        To conduct investigation, study and legal research on
                        various issues of indigenous peoples with a focus on
                        indigenous women and children including international
                        laws relating to indigenous women and children;
                    </li>
                    <li>
                        To advocate for socioeconomic, cultural, educational,
                        and intellectual agenda and issues of indigenous women
                        and children
                    </li>
                    <li>
                        To raise legal awareness among indigenous peoples and
                        marginalized communities to eliminate all forms of
                        discrimination, exploitation and violence against
                        indigenous women and children through publication,
                        partnering media, conducting trainings, workshops,
                        interaction, seminar, symposium, dialogues and talk
                        programs on indigenous women’s and children’s issues;
                    </li>
                    <li>
                        To monitor incidences of human rights violation, with a
                        focus on violence against indigenous women and children,
                        and carry out field investigation, if necessary;
                    </li>
                    <li>
                        To work for effective implementation of international
                        standards of human rights with a focus on The United
                        Nations Declaration on the Rights of Indigenous Peoples
                        (UNDRIP), Convention 169 – Indigenous and Tribal Peoples
                        Convention, 1989 and Convention on the Elimination of
                        All Forms of Discrimination Against Women (CEDAW).
                    </li>
                    <li>
                        To work for political rights of indigenous women
                        especially on the representation of indigenous women at
                        various levels of state from federal to local level.
                    </li>
                </ol>
            </div>
            <div className='mission-vision-img'>
                <img src={Mission1} alt='Inwolag members with Donor' />
                <img src={Mission2} alt='Indigenous awareness Program' />
                <img src={Mission3} alt='Inwolag team' />
            </div>
        </section>
    )
}
