import React from 'react'

import '../../../css/project.css'

// images

import Kaushila from '../../../assets/images/projects/kagaapay/workshop/kathmandu/1.jpg'
export default function KagaapayWorkshopKathmandu() {
    return (
        <section className='project-area'>
            <div className='center-container padded-container'>
                <h3>Kathmandu Workshop</h3>
                <div className='project-image-container'>
                    <figure>
                        <img src={Kaushila} alt='Bina Nagarkoti' />
                        <figcaption>
                            Adv Kaushila Rai on the Kathmandu Workshop
                        </figcaption>
                    </figure>
                </div>
                <div className='project-description'>
                    <p>
                        "Workshop on the Role of responsible local bodies in
                        implementation of laws and policies for Reducing
                        gender-based violence" program was conducted on 27th
                        Ashad, 2080(July 12, 2023) Wednesday, Alfa House,
                        Baneshor, Kathmandu. It is the part of workshop and
                        awareness program activity of Legal and Psychosocial
                        counseling for Feminist Resilience in the face of
                        COVID-19, Phas II supported by WFA Kaagapay fund
                        PhaseII. The workshop aimed to discuss and address GBV
                        issues of the victims, the role of local government
                        bodies, implementation of existing laws and policies and
                        identify potential areas for improvement to better
                        protect the rights and safety of Indigenous women. Local
                        government representatives, lawyers, GBV victims from
                        the Kathmandu, Bhaktapur and Lalitpur districts
                        participated in the program. The workshop program was
                        conducted by Secretary of INWOLAG Adv. Kaushila Rai. The
                        program started at 8am which was chaired by Chairperson
                        of INWOLAG Adv. Indira K. Shreesh, Chief Guest Honorable
                        Deputy Speaker of House of the Representatives Indira
                        Rana inaugurated ceremony by lighting the lamp and the
                        program formally started with the National anthem. The
                        welcome of the guests, introduction about the
                        organization and objectives of the workshop was briefed
                        by the Chairperson Adv Indira Shreesh. The Nepali
                        translation of CEDAW GR No.39 (2022) on the Rights if
                        Indigenous Women and Girls was also launched by Chief
                        Guest and Guests of the program for public, published by
                        the INWOLAG supported by FIMI. The second session
                        started with the presentation from Program Officer Adv
                        Dikshya Laxmi Shakya briefing about the project. She
                        explained the objectives, activities of the project,
                        also discussed about the cases of legal aid provided by
                        the INWOLAG. She explained on the laws and policies
                        related to rape, incest, domestic violence and also
                        violence relating to Sexual Offences and presented on
                        situational status of the cases of the working
                        districts. The Deputy Superintendent of Police from
                        Nepal Police Headquarter, Ministry of Women, Children
                        and Senior citizen Service Directorate Ms Rupa Thapa
                        presented on the topic "Role of Nepal Police for
                        Reducing Women Violence". She briefed about the
                        background on the Women, Children and Senior Citizen
                        Service Centers, the statistical report on the gender
                        violence especially on indigenous women, and attempts of
                        the Nepal Police in reducing gender violence. She
                        informed Nepal Police Women, Children and Senior
                        Citizens Service Centers are actively operating under
                        the direct supervision of Women, Children and Senior
                        Citizen Service Directorate (WCSCSD) in all 77
                        districts. She also informed about the role and duties
                        of Nepal Police in preventing the gender violence which
                        included strengthening, broadening the scope and
                        efficiency of women, children and senior citizen service
                        centers, building police capacities especially in
                        relation to handling of GBV related cases, collecting,
                        compiling and scientifically analyzing disaggregated
                        data on crime against women, children and senior citizen
                        from WCSCSCs all across the country, she further
                        explained production and publication of informative
                        materials on GBV and raising public awareness on
                        prevention of Crime Against Women, Children and Senior
                        Citizen. Also she emphasized organizations for
                        coordinating and networking with concerned stakeholders
                        in prevention and response to GBV. The Program
                        Coordinator of INWOLAG Ms. Arzoo Rana gave overview on
                        CEDAW, the existing law, and policies, relevant to the
                        violence against women, especially focusing on the
                        challenges that Indigenous women face. Likewise,
                        recently released GR-39 of the CEDAW committee was
                        discussed among the participants. She briefed about the
                        importance if the CEDAW and its relation with the
                        indigenous women and the role of the government for
                        implementation of laws. The Psychosocial counsel for the
                        project Ms. Sita Lama discussed on the importance of
                        Psychosocial counseling for GBV victims. She also
                        discussed the importance of mental health and the need
                        to focus on the mental health of especially women who
                        have been through any form of violence as it will have a
                        profound and lasting impact on their psychological
                        well-being. The participants showed interest and raised
                        questions regarding the issues. The floor was later
                        opened for discussion from the participants where many
                        issues and questions from the participants were
                        discussed relating to rights of women and mental health
                        issues. The local officers responded by welcoming the
                        suggestion through making relationship between the
                        organization and government, coordinating with
                        organizations for activities to resolve GBV issues, also
                        cooperating with victims to coordinate on the cases. The
                        meaningful participation of the local representatives
                        was remarkable, which showcased the enthusiasm to end
                        the gender based violence issues. The program was
                        concluded by thanking the participants presented for the
                        program.
                    </p>
                </div>
            </div>
        </section>
    )
}
