import React from 'react'

import '../../../css/project.css'

// images
import GroupPhoto from '../../../assets/images/projects/kagaapay/duwakot/1.jpg'

export default function KagaapayDuwakot() {
    return (
        <section className='project-area'>
            <div className='center-container padded-container'>
                <h3>Bhaktapur, Changunarayan, Duwakot-2,2023/01/04</h3>
                <div className='project-image-container'>
                    <figure>
                        <img src={GroupPhoto} alt='Participants with banner' />
                        <figcaption>
                            Group of Indigenous Women along with the Banner
                            participating in Bhaktapur Awareness
                        </figcaption>
                    </figure>
                </div>
                <div className='project-description'>
                    <p>
                        Indigenous Women Lawyers Awareness Group (INWOLAG)
                        organized one day program on 14th Poush, 2079, Thursday
                        at Indrakamal Sahakari Krishi Bhawan,Itahiti, Godawari
                        Municipality ward no 9, Lalitpur. Total 44 local
                        indigenous women from the Nagarkoti community
                        participated in the program.
                    </p>
                    <p>
                        The first session of the program started formally
                        welcomed the Chief guests Chairperson of ward no 9,Aatma
                        Ram Thapa, guests-ward member Jagat Bahadur Singtan,
                        Dalit women member, Jamuna Balkoti, Executive member
                        Bina Nagarkoti and Chairperson of Pasa Misa Nagarkoti
                        Samuha, Kali Maya Nagarkoti. The formal introduction of
                        the INWOLAG and the objectives of the program were
                        briefly explained by Adv. Dikshya Shakya, Legal Officer
                        of the INWOLAG. The formal session was concluded with
                        thanks by the Chairperson of INWOLAG, Adv Indira Shreesh
                        after the brief speech by the respective guests and
                        chief guest.
                    </p>
                    <p>
                        The second session of the program started at 12.30 with
                        the brief introduction of the participants. Most of the
                        participants were from the Duwakot community who were
                        doing local business, farming, and most were housewives
                        with no other income generating activities. The
                        Executive Board Member of INWOLAG Adv. Yogita Rai
                        presented on the Constitutional Rights of Women in Nepal
                        explaining the fundamental rights, Sexual Violence,
                        Domestic Violence, and the process of Legal Treatment.
                        She further explained the quasi judicial bodies recently
                        formed in the local government. She also gave details on
                        the facilities that have been provided to the indigenous
                        community by the law. Besides that she explained the
                        procedures of filing the complaint and suit on violence.
                        She further continued the explanation on the violence
                        relating to Sexual Offences. She gave brief explanation
                        on the rape, incest, and domestic violence relating to
                        the sex.She described the participants about the
                        punishment and limitations of the cases relating to the
                        violence.
                    </p>
                    <p>
                        The secretary of INWOLAG Adv Kaushila Rai presented on
                        the topics of rights of women, collective rights of
                        indigenous women, introduce about the violence, its
                        types and access to justice. She further gave examples
                        of the cases that the indigenous women face domestically
                        and outside the house. She presented on the Gender Based
                        Violence issues where she explained about the violence
                        occurring because of differentiation of gender. She
                        further explained various types of gender based violence
                        issues regarding the Partition, Polygamy, Marriage and
                        Divorce and explained briefly on the provisions of laws
                        relating to them. She described the participants about
                        the punishment and limitations of the cases relating to
                        the violence. The floor was later opened for discussion
                        from the participants where many issues and questions
                        from the participants were discussed relating to
                        divorce, partition and property rights of women. The
                        participants told they have been victim of the domestic
                        violence and were deprived of ancestral property for
                        partition. Atlas, the secretary of INWOLAG concluded the
                        program by thanking the participants presented for the
                        program.
                    </p>
                </div>
            </div>
        </section>
    )
}
