import React, { useState, useRef, useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPhone,
    faScaleBalanced,
    faHandshake,
    faPersonChalkboard,
} from '@fortawesome/free-solid-svg-icons'

import './css/activities.css'
import { activitesDesc } from './assets/activites-desc'

function Activities() {
    const [selectedActivity, setSelectedActivity] = useState(0)
    const legalRef = useRef(null)
    const networkRef = useRef(null)
    const phoneRef = useRef(null)
    const awarenessRef = useRef(null)
    const prevSelctedActivityRef = useRef(0)
    useEffect(() => {
        prevSelctedActivityRef.current = selectedActivity
        AOS.init()
    }, [selectedActivity])

    function handleActivitySelection(selectedActivityIndex) {
        if (selectedActivity !== selectedActivityIndex) {
            setSelectedActivity(selectedActivityIndex)
            switch (selectedActivityIndex) {
                case 0:
                    legalRef.current.classList.add('selected-activity')
                    break
                case 1:
                    phoneRef.current.classList.add('selected-activity')
                    break
                case 2:
                    networkRef.current.classList.add('selected-activity')
                    break
                case 3:
                    awarenessRef.current.classList.add('selected-activity')
                    break
                default:
                    console.log('No activity selected.')
            }
            switch (prevSelctedActivityRef.current) {
                case 0:
                    legalRef.current.classList.remove('selected-activity')
                    break
                case 1:
                    phoneRef.current.classList.remove('selected-activity')
                    break
                case 2:
                    networkRef.current.classList.remove('selected-activity')
                    break
                case 3:
                    awarenessRef.current.classList.remove('selected-activity')
                    break
                default:
                    console.log('No activity selected.')
            }
        }
    }

    return (
        <section className='activities padded-container'>
            <h2>What We Do</h2>
            <ul className='activities-pagination' data-aos='flip-up'>
                <li
                    key={0}
                    className='selected-activity activity'
                    onClick={() => {
                        handleActivitySelection(0)
                    }}
                    ref={legalRef}
                >
                    <FontAwesomeIcon
                        icon={faScaleBalanced}
                        className='activity-icon'
                    />
                    Legal Aid
                </li>
                <li
                    key={1}
                    className='activity'
                    onClick={() => {
                        handleActivitySelection(1)
                    }}
                    ref={phoneRef}
                >
                    <FontAwesomeIcon icon={faPhone} className='activity-icon' />
                    Phone Counseling
                </li>
                <li
                    key={2}
                    className='activity'
                    onClick={() => {
                        handleActivitySelection(2)
                    }}
                    ref={networkRef}
                >
                    <FontAwesomeIcon
                        icon={faHandshake}
                        className='activity-icon'
                    />
                    Networking
                </li>
                <li
                    key={3}
                    className='activity'
                    onClick={() => {
                        handleActivitySelection(3)
                    }}
                    ref={awarenessRef}
                >
                    <FontAwesomeIcon
                        icon={faPersonChalkboard}
                        className='activity-icon'
                    />
                    Awareness Program
                </li>
            </ul>
            <article className='activity-desc'>
                {activitesDesc[selectedActivity]}
            </article>
        </section>
    )
}

export default Activities
