import React, { useState } from 'react'

import '../css/donate.css'

export default function Donate() {
    const [showBankDetails, setShowBankDetails] = useState(false)

    return (
        <section className='donate-hero'>
            <div className='content' data-aos='fade-in'>
                <h1 className='title'>Be the Change</h1>

                <h2 className='subtitle'>
                    Support Inwolag in its journey to help indigenous women.
                </h2>

                <button type='button' onClick={() => setShowBankDetails(true)}>
                    Bank Details
                </button>
                {showBankDetails ? (
                    <p data-aos='fade-in'>
                        Bank
                        <br /> A/C Number:
                        <br /> Bank A/C Name:
                        <br />
                    </p>
                ) : null}
            </div>
        </section>
    )
}
