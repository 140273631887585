import React from 'react'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark } from '@fortawesome/free-solid-svg-icons'
import '../css/work-area.css'
export default function Projects() {
    return (
        <section className='work-area'>
            <div className='center-container padded-container'>
                <h2>Projects</h2>
                <ul className='work-list padded-container'>
                    <Link to='activism-project'>
                        <li>
                            <a href=''>
                                <FontAwesomeIcon icon={faBookmark} />
                                <span>
                                    Interactive Program on Indigenous Women
                                    Leadership Building for Activism, Access to
                                    Resources and Resilience Program
                                </span>
                            </a>
                        </li>
                    </Link>
                    <Link to='counseling-for-feminist-resilience'>
                        <li>
                            <a href=''>
                                <FontAwesomeIcon icon={faBookmark} />
                                <span>
                                    Legal and Psychological Counselling for
                                    Feminist Resilience in the face of COVID-19,
                                    Phase II
                                </span>
                            </a>
                        </li>
                    </Link>
                    <li>
                        <FontAwesomeIcon icon={faBookmark} />
                        <span>
                            Legal Awareness Programs of KAAGAPAY Project
                        </span>
                        <Link to='kagaapay-awareness-godawari'>
                            <li>
                                <a href=''>
                                    <FontAwesomeIcon icon={faBookmark} />
                                    <span>
                                        Lalitpur,Godawari, Chhampi-9, 2022/12/29
                                    </span>
                                </a>
                            </li>
                        </Link>
                        <Link to='kagaapay-awareness-duwakot'>
                            <li>
                                <a href=''>
                                    <FontAwesomeIcon icon={faBookmark} />
                                    <span>
                                        Bhaktapur, Changunarayan,
                                        Duwakot-2,2023/01/04
                                    </span>
                                </a>
                            </li>
                        </Link>
                        <Link to='kagaapay-awareness-banke'>
                            <li>
                                <a href=''>
                                    <FontAwesomeIcon icon={faBookmark} />
                                    <span>
                                        Banke, Khajura, Bageswori-3, 2023/01/10
                                    </span>
                                </a>
                            </li>
                        </Link>
                        <Link to='kagaapay-awareness-kathmandu'>
                            <li>
                                <a href=''>
                                    <FontAwesomeIcon icon={faBookmark} />
                                    <span>
                                        Kathmandu, Budhanilkantha, Chapali-2,
                                        2023/01/06
                                    </span>
                                </a>
                            </li>
                        </Link>
                        <Link to='kagaapay-awareness-rupandehi'>
                            <li>
                                <a href=''>
                                    <FontAwesomeIcon icon={faBookmark} />
                                    <span>
                                        Rupandehi, Tilottama, Semarabajar,
                                        2023/01/04
                                    </span>
                                </a>
                            </li>
                        </Link>
                        <Link to='kagaapay-awareness-kavre'>
                            <li>
                                <a href=''>
                                    <FontAwesomeIcon icon={faBookmark} />
                                    <span>
                                        Kavre, Panchkhal, Samudayik Bhawan-12,
                                        2023/01/04
                                    </span>
                                </a>
                            </li>
                        </Link>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faBookmark} />
                        <span>Workshop Programs of KAAGAPAY Project</span>
                        <Link to='kagaapay-workshop-kavre'>
                            <li>
                                <a href=''>
                                    <FontAwesomeIcon icon={faBookmark} />
                                    <span>Kavre,Dhulikhel, 2023/06/14</span>
                                </a>
                            </li>
                        </Link>
                        <Link to='kagaapay-workshop-banke'>
                            <li>
                                <a href=''>
                                    <FontAwesomeIcon icon={faBookmark} />
                                    <span>Banke, Nepalgunj, 2023/07/21</span>
                                </a>
                            </li>
                        </Link>
                        <Link to='kagaapay-workshop-kathmandu'>
                            <li>
                                <a href=''>
                                    <FontAwesomeIcon icon={faBookmark} />
                                    <span>Kathmandu, Baneshor, 2023/07/12</span>
                                </a>
                            </li>
                        </Link>
                        <Link to='kagaapay-workshop-rupandehi'>
                            <li>
                                <a href=''>
                                    <FontAwesomeIcon icon={faBookmark} />
                                    <span>
                                        Rupandehi, Tilottama, 2023/07/20
                                    </span>
                                </a>
                            </li>
                        </Link>
                    </li>
                </ul>
            </div>
        </section>
    )
}
